import React from "react";
import theme from "theme";
import { Theme, Text, Box, Strong, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Summit Haven Ski Retreat
			</title>
			<meta name={"description"} content={"Yamaç Dinginliği, Dağ İhtişamı - Summit Haven'daki Maceranız Burada Başlıyor"} />
			<meta property={"og:title"} content={"Summit Haven Ski Retreat"} />
			<meta property={"og:description"} content={"Yamaç Dinginliği, Dağ İhtişamı - Summit Haven'daki Maceranız Burada Başlıyor"} />
			<meta property={"og:image"} content={"https://laxigenturra.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://laxigenturra.com/img/6347621.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://laxigenturra.com/img/6347621.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://laxigenturra.com/img/6347621.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://laxigenturra.com/img/6347621.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://laxigenturra.com/img/6347621.png"} />
			<meta name={"msapplication-TileImage"} content={"https://laxigenturra.com/img/6347621.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="120px 0 290px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-11"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					Summit Haven'a Ulaşın
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--dark"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					Hizmetin Dağlar Kadar Yüksek Olduğu Yer
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="48px"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--dark" margin="0px 0px 6px 0px">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Konum
							</Strong>
						</Text>
						<Text margin="0px 0px 24px 0px" font="--lead" color="--darkL2">
							Hisarcık Erciyes, 38220 Melikgazi/Kayseri
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--dark" margin="0px 0px 6px 0px">
							<Strong>
								Telefon
							</Strong>
						</Text>
						<Text margin="0px 0px 24px 0px" font="--lead" color="--darkL2">
							+90 352 342 3917
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--lead" color="--dark" margin="0px 0px 6px 0px">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								E-posta
							</Strong>
						</Text>
						<Text margin="0px 0px 24px 0px" font="--lead" color="--darkL2">
							contact@laxigenturra.com
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});